import React, { useState, useContext, useCallback } from "react"
import { navigate } from "gatsby"
import {
  Page,
  Layout,
  Card,
  DisplayText,
  Icon,
  Stack,
  ButtonGroup,
  Button,
  TextContainer,
  FormLayout,
  Toast,
  Frame,
} from "@shopify/polaris"
import {
  ExternalSmallMinor,
  QuestionMarkMajor,
  ClipboardMinor,
  CirclePlusOutlineMinor,
} from "@shopify/polaris-icons"
import { doc } from 'firebase/firestore';
import { useDocumentData } from "react-firebase-hooks/firestore"
import FirebaseContext from "../../providers/firebase"
import styled from "styled-components"
import CreateOfferSteps from "./offers/createOfferSteps"
const PageWrapper = styled.div`
  .Polaris-Page {
    max-width: 110rem;
  }
`
const OnboardingLastStepWrapper = styled.div`
  padding: 50px 0 10px 0;
  .preview-button {
    padding: 25px 0 15px 0;
    .Polaris-Button {
      width: 485px;
    }
  }
  .instructions {
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    .instructions-image {
      max-width: 575px;
      margin: 0 auto 20px;
      padding: 10px;
      border: 1px solid #aaaaaa;
      border-radius: 8px;
      background: #f8f8f8;
      div {
        border: 1px solid #aaaaaa;
        border-radius: 8px;
        img {
          max-width: 550px;
        }
      }
    }
  }
  .action-buttons {
    padding: 15px 0 25px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .checkout-editor-button {
      .Polaris-Button {
        border: 2px solid #008060;
      }
    }
    .add-app-block {
      color: #2e61ce;
      font-size: 12px;
      display: flex;
      justify-content: center;
      flex-direction: row;
      svg {
        fill: #2e61ce;
      }
    }
    .save-button {
      .Polaris-Button {
        background: black;
        min-height: auto;
      }
    }
    .extension {
      border: 1px solid #ebebeb;
      padding: 8px 10px;
      border-radius: 5px;
      .extension-name {
        p:first-child {
          font-weight: bold;
          font-size: 10px;
        }
        p:nth-child(2) {
          font-size: 10px;
        }
        p:last-child {
          font-size: 8px;
          color: #727272;
        }
      }
    }
  }
`
const ButtonsWrapper = styled.div`
  margin-top: 15px;
  .Polaris-ButtonGroup__Item:first-child {
    button {
      color: #008060;
      svg {
        fill: #008060;
      }
    }
  }
`
function OnboardingLastStep(props) {
  const { location } = props
  const backLink = location && location.state && location.state.backLink
  const extensionName =
    location && location.state && location.state.extensionName
  const id = location && location.state && location.state.id
  const { firebase, shop, token } = useContext(FirebaseContext)
  const [active, setActive] = useState(false)
  const [toastActive, setToastActive] = useState(false)
  const shopDoc = doc(firebase.firestore, 'shops', shop);
  const [shopData, shopDataLoading, shopDataError] = useDocumentData(
    shopDoc,
    {
      transform: (snap) => snap.shopData,
    }
  )
  const copyIdToClipboard = (e, id) => {
    e.stopPropagation()
    navigator.clipboard
      .writeText(id)
      .then(() => toggleActive())
      .catch((err) => console.log("Error in copy customization id: ", err))
  }
  const toggleActive = useCallback(
    () => setToastActive((active) => !active),
    []
  )
  const toastMarkup = toastActive ? (
    <Toast
      content="Customization ID copied to clipboard"
      onDismiss={toggleActive}
      duration={1500}
    />
  ) : null
  const domain = shopData && shopData.domain
  return (
    <PageWrapper>
      <Page
        breadcrumbs={[{ content: "Offers", url: backLink }]}
        title={`Create new`}
        //primaryAction={{content: 'Save'}}
      >
        <Layout>
          <Layout.Section>
            <div style={{ margin: "20px 0 10px -25px" }}>
              <CreateOfferSteps step={3} />
            </div>
            <Card>
              <Card.Section>
                <OnboardingLastStepWrapper>
                  <div className="instructions">
                    <div className="instructions-image">
                      <div>
                        <img src="https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-last-step.gif?alt=media&token=fffe5ba2-326e-4306-b9fc-e780dcf5b014" />
                      </div>
                    </div>
                    <FormLayout>
                      <TextContainer spacing="loose">
                        <DisplayText size="medium">
                          Last step!
                          <br />
                          Enable the <b>{extensionName} extension</b> in your
                          checkout
                        </DisplayText>
                      </TextContainer>
                    </FormLayout>
                  </div>
                  <div className="action-buttons">
                    <Stack
                      distribution="center"
                      alignment="center"
                      spacing="tight"
                    >
                      <Button
                        onClick={() => {
                          setActive(true)
                          window.open(
                            `https://${domain}/admin/settings/checkout/editor`,
                            "_blank"
                          )
                        }}
                        primary
                      >
                        <Stack
                          distribution="center"
                          alignment="center"
                          spacing="tight"
                        >
                          <span>Go to checkout editor</span>
                          <Icon source={ExternalSmallMinor} color="base" />
                        </Stack>
                      </Button>
                      <DisplayText size="small">click</DisplayText>
                      <div className="add-app-block">
                        <Icon source={CirclePlusOutlineMinor} color="base" />
                        <span>Add app block</span>
                      </div>
                      <DisplayText size="small">select</DisplayText>
                      <div className="extension">
                        <Stack spacing="tight">
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/logo-onboarding-small.png?alt=media&token=956b4a16-ddbb-451d-afab-61b7ba1dc19f"
                            width="14"
                          />
                          <div className="extension-name">
                            <p>{extensionName}</p>
                            <p>UpsellPlus Checkout Offers</p>
                            <p>Checkout</p>
                          </div>
                        </Stack>
                      </div>
                      {extensionName !== "Rewards Bar" && (
                        <DisplayText size="small">and hit</DisplayText>
                      )}
                      {extensionName !== "Rewards Bar" && (
                        <div className="save-button">
                          <Button size="small" primary>
                            Save
                          </Button>
                        </div>
                      )}
                    </Stack>
                    {extensionName === "Rewards Bar" && (
                      <div style={{ padding: "10px 0" }}>
                        <Stack
                          distribution="center"
                          alignment="center"
                          spacing="tight"
                        >
                          <DisplayText size="small">
                            configure the Rewards Bar settings and hit
                          </DisplayText>
                          <div className="save-button">
                            <Button size="small" primary>
                              Save
                            </Button>
                          </div>
                        </Stack>
                      </div>
                    )}
                    {id &&
                    <div style={{padding:'15px 0'}}>
                      <Stack distribution="center">
                        <div>Customization ID: {id}</div>
                        <div style={{cursor:'pointer'}} onClick={(e) => copyIdToClipboard(e, id)}>
                          <Icon source={ClipboardMinor} color="base"/>
                        </div>
                      </Stack>
                    </div>
                    }
                    <ButtonsWrapper>
                      <Stack distribution="center">
                        <ButtonGroup>
                          <Button
                            plain
                            onClick={() => {
                              if (window && window.$crisp) {
                                window.$crisp.push(["do", "chat:open"])
                              }
                            }}
                          >
                            <Stack
                              distribution="center"
                              alignment="center"
                              spacing="tight"
                            >
                              <Icon source={QuestionMarkMajor} color="base" />
                              <span>Get help</span>
                            </Stack>
                          </Button>
                          {active ? (
                            <Button
                              primary={true}
                              onClick={() => {
                                navigate(backLink, { replace: true })
                              }}
                            >
                              Mark as done
                            </Button>
                          ) : (
                            <Button primary disabled>
                              Mark as done
                            </Button>
                          )}
                        </ButtonGroup>
                      </Stack>
                    </ButtonsWrapper>
                  </div>
                </OnboardingLastStepWrapper>
              </Card.Section>
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
      <Frame>{toastMarkup}</Frame>
    </PageWrapper>
  )
}

export default OnboardingLastStep
